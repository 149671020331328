import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import * as styles from "./careers.module.scss"
import BenefitModals from "./benefits-modals"
import Testimonials from "./testimonial-carousel"

const Careers = ({ pageContext, location }) => {
  const pageTitle = "Careers"

  return (
    <Layout>
      <Seo title={pageTitle} />

      <div className={`${styles.wrapper}`}>
        <div className={`${styles.sky}`}>
          <h1>Careers</h1>
          <div className={`${styles.brandLogos}`}>
            <img
              src={
                require("../../assets/images/logos/brand-logos/logo-muck-color.svg")
                  .default
              }
              alt=""
            />
            <img
              src={
                require("../../assets/images/logos/brand-logos/logo-durango-color.svg")
                  .default
              }
              alt=""
            />
            <img
              src={
                require("../../assets/images/careers/logos/rocky.svg")
                  .default
              }
              alt=""
            />
            <img
              src={
                require("../../assets/images/careers/logos/xtratuf.svg")
                  .default
              }
              alt=""
            />
            <img
              src={
                require("../../assets/images/careers/logos/georgia.svg")
                  .default
              }
              alt=""
            />
            <img
              src={
                require("../../assets/images/logos/brand-logos/logo-ranger-color.svg")
                  .default
              }
              alt=""
            />
            <img
              src={
                require("../../assets/images/logos/brand-logos/logo-custom-fit-color.svg")
                  .default
              }
              alt=""
            />
          </div>
          <a
            href="https://recruiting.ultipro.com/ROC1011RBD/JobBoard/7e2cec98-83e5-418d-9e0e-1a03210a5c51/?q=&o=postedDateDesc&w=&wc=&we=&wpst="
            class="CTAblack"
            title="Careers"
          >
            Join Our Team!
          </a>
          <div className={`${styles.mountains}`}>
            <img
              src={
                require("../../assets/images/careers/career-mountains.png")
                  .default
              }
              alt=""
            />
          </div>
        </div>

        <div className={`${styles.intro}`}>
          <p>At Rocky Brands, we're more than just a company - we're a team driven by passion, innovation, and a commitment to quality. We empower our people to grow, collaborate, and make a lasting impact. Join us, and help shape the future of footwear and beyond.</p>
          <a
            href="https://recruiting.ultipro.com/ROC1011RBD/JobBoard/7e2cec98-83e5-418d-9e0e-1a03210a5c51/?q=&o=postedDateDesc&w=&wc=&we=&wpst="
            class="CTAblack"
            title="Careers"
          >
            View Open Positions
          </a>
        </div>

        <div id="benefits" className={`${styles.benefits}`}>
          <h2>Our Benefits</h2>
          <div className={`${styles.line}`}>&nbsp;</div>
          <BenefitModals />
        </div>

        <div className={`${styles.life}`}>
          <h2>Life at Rocky Brands</h2>
          <div className={`${styles.line}`}>&nbsp;</div>
          <div className={`${styles.lifeWrapper}`}>
            <img
              src={
                require("../../assets/images/careers/career-life.jpg")
                  .default
              }
              alt=""
            />
            <p>Rocky Brands Inc. is a community of passionate individuals dedicated to supporting those who work, explore, and live outdoors. We take pride in creating products we're proud to wear and use, with each team member playing an integral role in our mission. Our goal is to make Rocky Brands a place where people are excited to contribute, collaborate, and grow daily.</p>
          </div>
        </div>

        <div id="culture" className={`${styles.culture}`}>
          <h2>Our Culture</h2>
          <div className={`${styles.line}`}>&nbsp;</div>
          <div className={`${styles.cultureWrapper}`}>
            <div className={`${styles.copy}`}>
              <p>At Rocky Brands, Inc., we aim to provide a collaborative and innovative atmosphere that promotes our employees' authenticity. Employee development and internal mobility are key; we focus on supporting your career growth.</p>
              <p>Our people culture is built on our core values of Integrity, Responsibility, and Humility. We encourage ongoing engagement through performance feedback, opportunities for further education, employee resource groups, rewards, recognition, community involvement, and volunteerism. Attracting, retaining, and developing career professionals is crucial to our long-term success.</p>
            </div>
            <img
              src={
                require("../../assets/images/careers/career-culture.jpg")
                  .default
              }
              alt=""
            />
          </div>
        </div>

        <div id="testimonials" className={`${styles.testimonials}`}>
          <h2>Testimonials</h2>
          <div className={`${styles.line}`}>&nbsp;</div>
          <Testimonials />
        </div>

        <div className={`${styles.outro}`}>
          <h2>Ready to Join Our Team?</h2>
          <a
            href="https://recruiting.ultipro.com/ROC1011RBD/JobBoard/7e2cec98-83e5-418d-9e0e-1a03210a5c51/?q=&o=postedDateDesc&w=&wc=&we=&wpst="
            class="CTAblack"
            title="Careers"
          >
            View Open Positions
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default Careers
